// @ts-strict-ignore
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { captureException } from '@sentry/nextjs';
import { clamp } from 'lodash';
import { CSSProp, DefaultTheme } from 'styled-components';
import { useDispatch } from 'src/store/store';
import { setAlert } from 'src/components/alert/actions';
import Button from 'src/components/buttons/button';
import i18n from 'src/utils/translate';
import { ModalType } from 'src/constants/modalTypes';
import { showModal } from 'src/modules/shared/modal/actions';
import { trackFootfallEvent } from 'src/utils/footfall';
import { Event } from 'src/constants/footfall';
import { BackgroundImage, PreviewButtons, UploadButton } from 'src/components/imageUploadStyles';
type Props = {
  buttonText?: string;
  currentImageUrl: string;
  customCss: CSSProp<DefaultTheme>;
  dataTestId?: string;
  isEditProfile?: boolean;
  saveRequest: (formData: FormData) => Promise<{
    imageUrl: string;
  }>;
  type?: 'avatar' | 'banner';
};
export default function ImageUpload({
  buttonText = i18n.t('Upload'),
  currentImageUrl,
  customCss,
  dataTestId,
  isEditProfile,
  saveRequest,
  type
}: Props) {
  const dispatch = useDispatch();
  const imageRef = useRef(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [isGrabbing, setIsGrabbing] = useState(false);
  const [file, setFile] = useState(null);
  const [url, setUrl] = useState(currentImageUrl);
  const [startY, setStartY] = useState(0);
  const [posY, setPosY] = useState(0);
  useEffect(() => {
    setUrl(currentImageUrl);
  }, [currentImageUrl]);
  const isPreview = Boolean(file);
  const {
    getRootProps,
    getInputProps,
    open
  } = useDropzone({
    accept: {
      'image/*': ['.png', '.jpg', '.jpeg', '.gif']
    },
    disabled: isPreview,
    multiple: false,
    noDrag: true,
    onDrop: useCallback(files => {
      try {
        setFile(files[0]);
        setUrl(URL.createObjectURL(files[0]));
        getNewImageDimensions(files[0]);
      } catch (ex) {
        captureException(ex);
        dispatch(setAlert(i18n.t('There was a problem uploading your image.')));
      }
    }, [])
  });
  function getNewImageDimensions(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const image = new Image();
      image.src = (reader.result as string);
      image.onload = () => {
        setWidth(image.naturalWidth);
        setHeight(image.naturalHeight);
      };
    };
  }
  const formattedFileData = () => {
    const displayScale = width / imageRef.current.clientWidth;
    const y = Math.abs(Math.round(posY * displayScale));
    const formData = new FormData();
    formData.append('file', file);
    formData.append('x', String(0));
    formData.append('y', String(y));
    formData.append('width', String(width));
    formData.append('height', String(height - y));
    return formData;
  };
  const onSave = async () => {
    try {
      if (isEditProfile) trackFootfallEvent(Event.Clicked, {
        clickType: `added-${type}`
      });
      const {
        imageUrl
      } = await saveRequest(formattedFileData());
      setUrl(imageUrl);
      setFile(null);
      setPosY(0);
    } catch (ex) {
      captureException(ex);
      dispatch(setAlert(i18n.t('There was a problem uploading your image.')));
    }
  };
  const onCancel = () => {
    setFile(null);
    setUrl(currentImageUrl);
    setPosY(0);
  };
  const onMouseDown = event => {
    if (!file) return;
    event.preventDefault();
    setIsGrabbing(true);
    setStartY(event.clientY);
    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('mouseup', onMouseUp);
  };
  const onMouseMove = event => {
    const newY = posY + event.clientY - startY;
    // constrain y movement to not let the image above the boundary, with regard to the scale of the displayed image
    const scale = width / imageRef.current.clientWidth;
    const minY = imageRef.current.clientHeight - height / scale;
    const maxY = 0;
    setPosY(clamp(newY, minY, maxY));
  };
  const onMouseUp = () => {
    setIsGrabbing(false);
    window.removeEventListener('mousemove', onMouseMove);
    window.removeEventListener('mouseup', onMouseUp);
  };
  const triggerPrivacyTipsModal = event => {
    if (!isEditProfile) return;
    event.stopPropagation();
    trackFootfallEvent(Event.Clicked, {
      clickType: `clicked-add-${type}`
    });
    dispatch(showModal({
      modalType: ModalType.PrivacyTipsImages,
      modalProps: {
        onAccept: open
      }
    }));
  };
  return <BackgroundImage $backgroundImage={url} $customCss={customCss} $isGrabbing={isGrabbing} $isPreview={isPreview} $top={posY} ref={imageRef} onMouseDown={onMouseDown} data-sentry-element="BackgroundImage" data-sentry-component="ImageUpload" data-sentry-source-file="imageUpload.tsx">
      <div id="css-focusable" {...getRootProps({
      onClick: triggerPrivacyTipsModal
    })}> {/* eslint-disable-line react/jsx-props-no-spreading */}
        <input {...getInputProps()} /> {/* eslint-disable-line react/jsx-props-no-spreading */}
        {isPreview ? <PreviewButtons $isEditProfile={isEditProfile} $type={type}>
              <Button text={i18n.t('Save')} onClick={onSave} />
              <Button text={i18n.t('Cancel')} type="secondary" onClick={onCancel} />
            </PreviewButtons> : <UploadButton $isEditProfile={isEditProfile} $type={type}>
              <Button dataTestId={dataTestId} icon="plus" text={buttonText} type="secondary" />
            </UploadButton>}
      </div>
    </BackgroundImage>;
}