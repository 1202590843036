import styled, { css } from 'styled-components';

export const srOnly = css`
  position: absolute;
  top: auto;
  left: -10000px;
  width: 1px;
  height: 1px;
  overflow: hidden;
`;

export const H1 = styled.h1`
  ${srOnly}
`;

export const SrOnly = styled.span`
  ${srOnly}
`;
