import { useContext, useEffect, useState } from 'react';
import { ThemeContext } from 'styled-components';
import { MediaQueries } from 'styles-js/theme';

export default function mediaQuery(query: string) {
  return window.matchMedia(`(${query})`).matches;
}

export function useMediaQuery(queryName: keyof MediaQueries) {
  const [isMatch, setIsMatch] = useState(false);

  const query = useContext(ThemeContext)?.[queryName];

  useEffect(() => {
    const mediaQuery = window.matchMedia(`(${query})`);
    setIsMatch(mediaQuery.matches);
    mediaQuery.addEventListener('change', () => setIsMatch(mediaQuery.matches));
    return () => mediaQuery.removeEventListener('change', () => setIsMatch(mediaQuery.matches));
  }, [query]);

  return isMatch;
}
