export enum language {
    EN = 'en',
    ES = 'es',
    PT = 'pt',
  }

export const langNames: { [key: string]: string } = {
  'en': 'English',
  'es': 'Español',
  'pt': 'Português',
};
