import Head from 'next/head';
import { useSelector } from 'src/store/store';
import { Metadata } from 'src/constants/types';
import config from 'config/config';
export default function HeadSeo({
  canonical,
  published_time,
  modified_time,
  title = 'HealthUnlocked | The social network for health',
  description = "We are the world's largest online support network.\nAsk questions. Get answers.\nTalk to others who've been there before.\n\n#UnlockYourHealth",
  image = `${config.cdn.static}img/home/HUsocialimage.png`,
  robots
}: Metadata): JSX.Element {
  const unreadMessagesCount = useSelector(({
    context
  }) => context?.unreadMessagesCount) ?? 0;
  const unreadNotificationsCount = useSelector(({
    context
  }) => context.notifications?.unreadNotificationsCount) ?? 0;
  let notifications = '';
  if (unreadMessagesCount || unreadNotificationsCount) {
    notifications = `(${unreadMessagesCount + unreadNotificationsCount}) `;
  }
  return <Head data-sentry-element="Head" data-sentry-component="HeadSeo" data-sentry-source-file="head.tsx">
      <title>{notifications + title}</title>
      <meta content={title} property="og:title" data-sentry-element="meta" data-sentry-source-file="head.tsx" />
      <meta content={description} name="description" data-sentry-element="meta" data-sentry-source-file="head.tsx" />
      <meta content={description} property="og:description" data-sentry-element="meta" data-sentry-source-file="head.tsx" />
      <meta content={image} property="og:image" data-sentry-element="meta" data-sentry-source-file="head.tsx" />
      <meta content={image} property="og:image:secure_url" data-sentry-element="meta" data-sentry-source-file="head.tsx" />
      {canonical && <link href={canonical} rel="canonical" />}
      {published_time && <meta content={published_time} property="article:published_time" />}
      {modified_time && <meta content={modified_time} property="article:modified_time" />}
      {robots && <meta content={robots} name="robots" />}
    </Head>;
}