import i18n from 'src/utils/translate';
type GetUnfollowText = {
  followText: string;
  followType: string;
  hasIcon: boolean;
  isFollowing: boolean;
  isHover: boolean;
  unFollowText?: string;
};
export function useButtonText({
  followType,
  isHover,
  hasIcon,
  followText,
  unFollowText,
  isFollowing
}: GetUnfollowText): {
  buttonText: string;
  ariaLabel: string;
} {
  switch (followType) {
    case 'post':
      if (isFollowing) {
        const defaultText = isHover ? i18n.t('Unsave') : i18n.t('Saved');
        return {
          buttonText: unFollowText ?? defaultText,
          ariaLabel: i18n.t('Unsave post')
        };
      }
      return {
        buttonText: followText,
        ariaLabel: followText
      };
    case 'user':
      if (isFollowing) {
        const defaultText = isHover ? i18n.t('Unfollow') : i18n.t('Following');
        return {
          buttonText: unFollowText ?? defaultText,
          ariaLabel: i18n.t('Unfollow user')
        };
      }
      return {
        buttonText: followText,
        ariaLabel: followText
      };
    case 'community':
    default:
      if (isFollowing) {
        const defaultText = !hasIcon || isHover ? i18n.t('Leave') : i18n.t('');
        return {
          buttonText: unFollowText ?? defaultText,
          ariaLabel: i18n.t('Leave community')
        };
      }
      return {
        buttonText: followText,
        ariaLabel: followText
      };
  }
}