// @ts-strict-ignore
import styled from 'styled-components';
import i18n from 'src/utils/translate';
import Button from 'src/components/buttons/button';
export function ToggleButton({
  isReduced,
  setIsReduced,
  isWhite = false
}) {
  const text = isReduced ? i18n.t('Show footer') : i18n.t('Hide footer');
  const onClick = () => {
    setIsReduced(!isReduced);
    setTimeout(() => window.scrollTo(0, document.body.scrollHeight), 0);
  };
  return <StyledButton isWhite={isWhite} type="none" onClick={onClick} data-sentry-element="StyledButton" data-sentry-component="ToggleButton" data-sentry-source-file="toggleButton.tsx">
      {text}
    </StyledButton>;
}
const StyledButton = styled(Button)<{
  isWhite?: boolean;
}>`
  color: ${({
  isWhite,
  theme
}) => isWhite ? theme.colorTextWhite : theme.colorBlack};
  text-decoration: underline;

  &:hover, &:focus, &:active {
    color: ${({
  isWhite,
  theme
}) => isWhite ? theme.colorTextWhite : theme.colorGreyDark};
    text-decoration: underline;
  }

  @media (${({
  theme
}) => theme.overScreenSmall}) {
    display: none;
  }
`;