// @ts-strict-ignore
import { BBCODE } from 'src/constants/regex';

export const pluralize = (singular: string, plural: string, count: number): string => {
  return (count > 1 || count === 0) ? plural.replace('%1$s', String(count)) : singular.replace('%1$s', String(count));
};

export const stripBBTags = (str: string): string => {
  return str.replace(BBCODE, '');
};

export const arrayToSentence = (arr): string =>
  arr.reduce((prev, curr, i) => {
    return prev + curr + ((i === arr.length - 2) ? ' and ' : ', ');
  }, '')
    .slice(0, -2);

export const stringToKey = (str = String(Math.random())): string => {
  if (typeof str !== 'string') return String(Math.random());
  return str?.slice(0, 16);
};

