import classNames from 'classnames';
import styled from 'styled-components';
import Icon, { IconType } from 'src/components/icon';
export type Brand = Extract<IconType, 'facebook' | 'twitter' | 'instagram' | 'youtube' | 'mail'>;
type Props = {
  brand: Brand;
  hasBrandColor?: boolean;
  onClick?: () => void;
};
export default function SocialButton({
  brand,
  hasBrandColor = true,
  onClick = () => {}
}: Props) {
  const classes = classNames({
    [brand]: hasBrandColor,
    'no-brand-color': !hasBrandColor
  });
  return <Button aria-label={brand} className={classes} title={brand} type="button" onClick={onClick} data-sentry-element="Button" data-sentry-component="SocialButton" data-sentry-source-file="socialButton.tsx">
      <Icon icon={brand} data-sentry-element="Icon" data-sentry-source-file="socialButton.tsx" />
    </Button>;
}
const Button = styled.button`
  border-radius: 35px;
  width: 35px;
  height: 35px;
  font-size: 1.2em;
  border: none;
  background-color: ${({
  theme
}) => theme.colorBlack};
  color: ${({
  theme
}) => theme.colorWhite};
  padding: 0;
  margin-right: 8px;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  transition: opacity 0.5s ease;

  &:empty:before {
    margin-right: 1px;
  }

  &:hover, &:focus, &:active {
    cursor: pointer;
    opacity: 0.7;
  }

  &.no-brand-color {
    background-color: ${({
  theme
}) => theme.colorBlack};
  }

  &.facebook {
    background-color: ${({
  theme
}) => theme.colorFacebook};
  }

  &.twitter {
    background-color: ${({
  theme
}) => theme.colorTwitter};
  }

  &.instagram {
    background-color: ${({
  theme
}) => theme.colorInstagram};
  }

  &.youtube {
    background-color: ${({
  theme
}) => theme.colorYoutube};
  }
`;