import { useCallback, useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';
export function useNavBarOverflow(childrenNumber: number) {
  const navRef = useRef<HTMLDivElement>(null);
  const [visibleItemsNumber, setVisibleItemsNumber] = useState(childrenNumber);
  const checkOverflow = useCallback(() => {
    if (navRef.current) {
      const {
        scrollWidth,
        clientWidth,
        scrollHeight,
        clientHeight
      } = navRef.current;
      if (scrollWidth > clientWidth || scrollHeight > clientHeight) {
        setVisibleItemsNumber(visibleItemsNumber - 1);
      }
    }
  }, [visibleItemsNumber]);
  useEffect(() => {
    const handleResize = debounce(() => {
      setVisibleItemsNumber(childrenNumber);
      checkOverflow();
    }, 500);
    checkOverflow();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [childrenNumber, checkOverflow]);
  return {
    visibleItemsNumber,
    navRef
  };
}