// @ts-strict-ignore
import { SyntheticEvent, ReactNode } from 'react';
import Link from 'next/link';
import styled from 'styled-components';
import Avatar, { Size } from 'src/components/avatar';
import { showSignupModal } from 'src/modules/shared/modal/actions';
import { trackFootfallEvent } from 'src/utils/footfall';
import { Event, FootfallContextType, Element, Section } from 'src/constants/footfall';
import i18n from 'src/utils/translate';
import { generateAvatarLinkFromHash } from 'src/utils/linkUtils';
import { useDispatch, useSelector } from 'src/store/store';
import { helpScoutArticle } from 'src/utils/helpScoutBeacon';
import { HIDDEN_USERNAME } from 'src/constants/hiddenUser';
type Props = {
  avatarSize?: Size;
  children?: ReactNode;
  className?: string;
  signupContext?: FootfallContextType;
  user: {
    avatar?: string;
    isDeleted?: boolean;
    isHidden?: boolean;
    profileImageSmall?: string;
    userImageHash?: string;
    username: string;
  };
  onClick?: (e: SyntheticEvent) => void;
};
export default function UserLink({
  avatarSize,
  children,
  className,
  user,
  signupContext = {
    section: Section.Untracked,
    element: Element.UserLink
  },
  onClick = () => {}
}: Props): JSX.Element {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(({
    context
  }) => !!context.currentUser);
  const onClickExtended = event => {
    onClick(event);
    if (!isLoggedIn) {
      event.preventDefault();
      trackFootfallEvent(Event.Clicked, {
        clickType: 'sign-up',
        clickMetadata: signupContext
      });
      dispatch(showSignupModal());
    }
  };
  const isHiddenOrDeleted = !user || user?.isDeleted || user?.isHidden || user.username === HIDDEN_USERNAME;
  const username = isHiddenOrDeleted ? i18n.t(HIDDEN_USERNAME) : user.username;
  const avatarSrc = user?.profileImageSmall || user?.avatar || generateAvatarLinkFromHash(user?.userImageHash);
  const content = avatarSize ? <Avatar name={user.username} size={avatarSize} url={avatarSrc} /> : username;

  // Open Help Scout article for hidden/deleted users
  if (isHiddenOrDeleted) {
    return <ArticleButton aria-label="What are hidden accounts?" className={className} onClick={() => helpScoutArticle('173-what-are-hidden-accounts')}>
        {children || content}
      </ArticleButton>;
  }
  return <Link aria-label={`View ${user.username} profile`} as={`/user/${user.username}`} className={className} href={`/user/profile?username=${user.username}`} onClick={onClickExtended} data-sentry-element="Link" data-sentry-component="UserLink" data-sentry-source-file="userLink.tsx">
      {children || content}
    </Link>;
}
const ArticleButton = styled.button`
  border: 0;
  padding: 0;
  appearance: none;
  background-color: transparent;
`;