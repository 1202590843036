// @ts-strict-ignore
import classNames from 'classnames';
import i18n from 'src/utils/translate';
import Section from 'src/modules/home/visitor/components/section';
import { trackFootfallEventWithHrefDelay } from 'src/utils/footfall';
import { Event, Section as FFSection } from 'src/constants/footfall';
import Icon from 'src/components/icon';
export const discoverLinks = [{
  id: 'communities',
  title: i18n.t('Popular communities'),
  links: [{
    id: 'adhd-parents',
    name: i18n.t('ADHD Parents Together')
  }, {
    id: 'advanced-prostate-cancer',
    name: i18n.t('Advanced Prostate Cancer')
  }, {
    id: 'anxiety-depression-support',
    name: i18n.t('Anxiety and Depression Support')
  }, {
    id: 'asthmauk',
    name: i18n.t('Asthma UK community forum')
  }, {
    id: 'bhf',
    name: i18n.t('British Heart Foundation')
  }, {
    id: 'blf',
    name: i18n.t('British Lung Foundation')
  }, {
    id: 'cllsupport',
    name: i18n.t('CLL Support Association')
  }, {
    id: 'couchto5k',
    name: i18n.t('Couch to 5K')
  }, {
    id: 'endometriosis-uk',
    name: i18n.t('Endometriosis UK')
  }, {
    id: 'fertility-network-uk',
    name: i18n.t('Fertility Network UK')
  }, {
    id: 'fibromyalgia-action-uk',
    name: i18n.t('Fibromyalgia Action UK')
  }, {
    id: 'theibsnetwork',
    name: i18n.t('IBS Network')
  }, {
    id: 'nkf-ckd',
    name: i18n.t('Kidney Disease')
  }, {
    id: 'lupusuk',
    name: i18n.t('LUPUS UK')
  }, {
    id: 'mpnvoice',
    name: i18n.t('MPN Voice')
  }, {
    id: 'mymsaa',
    name: i18n.t('My MSAA Community')
  }, {
    id: 'my-ocd',
    name: i18n.t('My OCD Community')
  }, {
    id: 'ovacome',
    name: i18n.t('My Ovacome')
  }, {
    id: 'nct',
    name: i18n.t('NCT')
  }, {
    id: 'nras',
    name: i18n.t('NRAS')
  }, {
    id: 'painconcern',
    name: i18n.t('Pain Concern')
  }, {
    id: 'parkinsonsmovement',
    name: i18n.t('Parkinsons Movement')
  }, {
    id: 'pmrgcauk',
    name: i18n.t('PMRGCAuk')
  }, {
    id: 'share-metastatic-support',
    name: i18n.t('SHARE Metastatic Breast Cancer')
  }, {
    id: 'thyroiduk',
    name: i18n.t('Thyroid UK')
  }, {
    id: 'nhsweightloss',
    name: i18n.t('Weight Loss Support')
  }, {
    id: 'communities',
    name: i18n.t('See all'),
    isMoreLink: true
  }]
}, {
  id: 'conditions',
  title: i18n.t('Common conditions'),
  links: [{
    id: 'anxiety',
    href: '/tag/anxiety',
    name: i18n.t('Anxiety')
  }, {
    id: 'atrial-fibrillation',
    href: '/tag/atrial-fibrillation',
    name: i18n.t('Atrial fibrillation')
  }, {
    id: 'chronic-obstructive-pulmonary-disease-copd',
    href: '/tag/chronic-obstructive-pulmonary-disease-copd',
    name: i18n.t('Chronic obstructive pulmonary disease')
  }, {
    id: 'clinical-depression',
    href: '/tag/clinical-depression',
    name: i18n.t('Clinical depression')
  }, {
    id: 'insomnia',
    href: '/tag/insomnia',
    name: i18n.t('Insomnia')
  }, {
    id: 'intestinal-and-stomach-conditions',
    href: '/tag/intestinal-and-stomach-conditions',
    name: i18n.t('Intestinal and stomach conditions')
  }, {
    id: 'liver-disease',
    href: '/tag/liver-disease',
    name: i18n.t('Liver disease')
  }, {
    id: 'respiratory-tract-infection',
    href: '/tag/respiratory-tract-infection',
    name: i18n.t('Respiratory tract infection')
  }, {
    id: 'lupus',
    href: '/tag/lupus',
    name: i18n.t('Lupus')
  }, {
    id: 'underactive-thyroid-hypothyroidism',
    href: '/tag/underactive-thyroid-hypothyroidism',
    name: i18n.t('Underactive thyroid hypothyroidism')
  }, {
    id: 'tags',
    href: '/tags',
    name: i18n.t('See all'),
    isMoreLink: true
  }]
}, {
  id: 'treatments',
  title: i18n.t('Common treatments'),
  links: [{
    id: 'alendronic-acid',
    href: '/tag/alendronic-acid',
    name: i18n.t('Alendronic acid')
  }, {
    id: 'amitriptyline',
    href: '/tag/amitriptyline',
    name: i18n.t('Amitriptyline')
  }, {
    id: 'amlodipine',
    href: '/tag/amlodipine',
    name: i18n.t('Amlodipine')
  }, {
    id: 'atorvastatin',
    href: '/tag/atorvastatin',
    name: i18n.t('Atorvastatin')
  }, {
    id: 'bisoprolol',
    href: '/tag/bisoprolol',
    name: i18n.t('Bisoprolol')
  }, {
    id: 'citalopram',
    href: '/tag/citalopram',
    name: i18n.t('Citalopram')
  }, {
    id: 'co-codamol',
    href: '/tag/co-codamol',
    name: i18n.t('Co codamol')
  }, {
    id: 'doxycycline',
    href: '/tag/doxycycline',
    name: i18n.t('Doxycycline')
  }, {
    id: 'flucloxacillin',
    href: '/tag/flucloxacillin',
    name: i18n.t('Flucloxacillin')
  }, {
    id: 'fluoxetine',
    href: '/tag/fluoxetine',
    name: i18n.t('Fluoxetine')
  }, {
    id: 'fybogel',
    href: '/tag/fybogel',
    name: i18n.t('Fybogel')
  }, {
    id: 'lansoprazole',
    href: '/tag/lansoprazole',
    name: i18n.t('Lansoprazole')
  }, {
    id: 'levothyroxine',
    href: '/tag/levothyroxine',
    name: i18n.t('Levothyroxine')
  }, {
    id: 'metformin',
    href: '/tag/metformin',
    name: i18n.t('Metformin')
  }, {
    id: 'methotrexate',
    href: '/tag/methotrexate',
    name: i18n.t('Methotrexate')
  }, {
    id: 'naproxen',
    href: '/tag/naproxen',
    name: i18n.t('Naproxen')
  }, {
    id: 'nitrofurantoin',
    href: '/tag/nitrofurantoin',
    name: i18n.t('Nitrofurantoin')
  }, {
    id: 'prednisolone',
    href: '/tag/prednisolone',
    name: i18n.t('Prednisolone')
  }, {
    id: 'pregabalin',
    href: '/tag/pregabalin',
    name: i18n.t('Pregabalin')
  }, {
    id: 'ramipril',
    href: '/tag/ramipril',
    name: i18n.t('Ramipril')
  }, {
    id: 'rivaroxaban',
    href: '/tag/rivaroxaban',
    name: i18n.t('Rivaroxaban')
  }, {
    id: 'sertraline',
    href: '/tag/sertraline',
    name: i18n.t('Sertraline')
  }, {
    id: 'tramadol',
    href: '/tag/tramadol',
    name: i18n.t('Tramadol')
  }, {
    id: 'venlafaxine',
    href: '/tag/venlafaxine',
    name: i18n.t('Venlafaxine')
  }, {
    id: 'warfarin',
    href: '/tag/warfarin',
    name: i18n.t('Warfarin')
  }, {
    id: 'zomorph',
    href: '/tag/zomorph',
    name: i18n.t('Zomorph')
  }, {
    id: 'tags',
    href: '/tags',
    name: i18n.t('See all'),
    isMoreLink: true
  }]
}, {
  id: 'programs',
  title: i18n.t('Educational Programs'),
  links: [{
    id: 'programs/practical-guide-for-dementia',
    name: i18n.t('A Practical Guide for Dementia')
  }, {
    id: 'programs/atrial-fibrillation-and-you',
    name: i18n.t('Atrial Fibrillation (AF) and You')
  }, {
    id: 'programs/covid-19-and-ocd',
    name: i18n.t('Coping with OCD during the COVID-19 outbreak')
  }, {
    id: 'programs/high-blood-pressure',
    name: i18n.t('Living With High Blood Pressure')
  }, {
    id: 'programs/living-with-atrial-fibrillation',
    name: i18n.t('Living with Atrial Fibrillation')
  }, {
    id: 'programs/living-with-lung-cancer',
    name: i18n.t('Living with Lung Cancer')
  }, {
    id: 'programs/stage-4-chronic-kidney-disease',
    name: i18n.t('Living with Stage 4 Chronic Kidney Disease')
  }, {
    id: 'programs/lower-your-cholesterol',
    params: '?week=sign-up',
    name: i18n.t('Lower your cholesterol')
  }, {
    id: 'programs/understanding-clinical-trials',
    name: i18n.t('Understanding Clinical Trials')
  }, {
    id: 'programs/your-hearing-matters',
    name: i18n.t('Your Hearing Matters')
  }]
}];
export default function DiscoverSection() {
  function renderLink({
    id,
    href,
    name,
    isMoreLink,
    params
  }) {
    const className = classNames({
      'home__link-more': isMoreLink
    });
    const linkHref = href || `/${id}${params ? params : ''}`;
    return <a className={className} href={linkHref} key={id} onClick={e => {
      e.preventDefault();
      const section = isMoreLink ? FFSection.TagsSeeAll : FFSection.Tag;
      trackFootfallEventWithHrefDelay(linkHref, Event.Clicked, {
        clickMetadata: {
          section,
          type: id
        },
        clickType: 'visitor-homepage'
      });
    }} data-sentry-component="renderLink" data-sentry-source-file="discoverSection.tsx">
        {name}
        {' '}
        {isMoreLink && <Icon icon="open-right" />}
      </a>;
  }
  return <Section data-sentry-element="Section" data-sentry-component="DiscoverSection" data-sentry-source-file="discoverSection.tsx">
      <div className="home__section-content--split">
        <div className="home__partners">
          <h2>{i18n.t('Discover your health')}</h2>
          <p>{i18n.t("We are leaders in support and information in over 300 different areas of health. Whatever you're looking for, we've got you covered.")}</p>
        </div>
      </div>
      <div className="home__common-links">
        {discoverLinks.map(linkSection => {
        const className = classNames({
          'home__link-section': true,
          'home__link-section--large': linkSection.links.length > 14,
          'home__link-section--small': linkSection.links.length < 10
        });
        return <div key={`section-${linkSection.id}`}>
              <h3>{linkSection.title}</h3>
              <div className={className}>
                {linkSection.links.map(renderLink)}
              </div>
            </div>;
      })}
      </div>
    </Section>;
}