import styled from 'styled-components';
import i18n from 'src/utils/translate';
import { textSmall } from 'styles-js/mixins/typography';
import { StyledSearchBar } from 'src/components/siteBar/sharedStyles';
export default function MobileSearchInput({
  onCancel
}: {
  onCancel: () => void;
}) {
  return <Container data-sentry-element="Container" data-sentry-component="MobileSearchInput" data-sentry-source-file="mobileSearchInput.tsx">
      <StyledSearchBar onSearch={onCancel} data-sentry-element="StyledSearchBar" data-sentry-source-file="mobileSearchInput.tsx" />
      <CancelButton onClick={onCancel} data-sentry-element="CancelButton" data-sentry-source-file="mobileSearchInput.tsx">{i18n.t('Cancel')}</CancelButton>
    </Container>;
}
const Container = styled.div`
  display: flex;
  margin: 0 12px;
`;
const CancelButton = styled.button`
  ${textSmall};
  margin-left: 10px;
  border: 0;
  padding: 0;
  appearance: none;
  background-color: transparent;
  cursor: pointer;
`;