import Link from 'next/link';
import { truncate } from 'underscore.string';
import styled from 'styled-components';
import Button from 'src/components/buttons/button';
import i18n from 'src/utils/translate';
import { showModal, closeModal } from 'src/modules/shared/modal/actions';
import { resendVerificationLink } from 'src/modules/shared/context/actions';
import { useDispatch, useSelector } from 'src/store/store';
import { ModalType } from 'src/constants/modalTypes';
import { textSmall, titleSmall } from 'styles-js/mixins/typography';
export default function VerificationBar() {
  const dispatch = useDispatch();
  const {
    currentUser,
    showVerificationBar
  } = useSelector(({
    context
  }) => context);
  if (!currentUser || !showVerificationBar) return null;
  const unverifiedEmail = truncate(currentUser?.unverifiedEmail || '', 40);
  const onResendEmailClick = () => {
    dispatch(resendVerificationLink());
    dispatch(closeModal());
  };
  const onInformationClick = () => {
    dispatch(showModal({
      modalType: ModalType.EmailConfirmation,
      modalProps: {
        unverifiedEmail,
        onResendEmailClick
      }
    }));
  };
  return <Bar className="js-verification-bar" data-sentry-element="Bar" data-sentry-component="VerificationBar" data-sentry-source-file="verificationBar.tsx">
      <Inner data-sentry-element="Inner" data-sentry-source-file="verificationBar.tsx">
        <Text data-sentry-element="Text" data-sentry-source-file="verificationBar.tsx">
          {i18n.t('Confirm your email by clicking the link we sent to %1$s').replace('%1$s', unverifiedEmail)}
          <Button icon="information" type="none" onClick={onInformationClick} data-sentry-element="Button" data-sentry-source-file="verificationBar.tsx" />
        </Text>
        <Buttons data-sentry-element="Buttons" data-sentry-source-file="verificationBar.tsx">
          <Button text="Resend email" type="danger" onClick={onResendEmailClick} data-sentry-element="Button" data-sentry-source-file="verificationBar.tsx" />
          <Link href="/settings#email-update_settings" data-sentry-element="Link" data-sentry-source-file="verificationBar.tsx">
            {i18n.t('Change email')}
          </Link>
        </Buttons>
      </Inner>
    </Bar>;
}
const Bar = styled.div`
  ${titleSmall}
  position: relative;
  top: ${({
  theme
}) => theme.navHeightTop};
  z-index: 1000;
  background-color: ${({
  theme
}) => theme.colorPinkDarker};
  color: ${({
  theme
}) => theme.colorWhite};
`;
const Inner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  min-height: ${({
  theme
}) => theme.navHeightTop};
  margin: 0 auto;
  padding: 5px 15px;
  gap: 15px;
  @media (${({
  theme
}) => theme.underScreenSmall}) {
    flex-direction: column;
    padding: 15px;
    gap: 5px;
  }
`;
const Text = styled.div`
  button {
    padding: 0 4px;
    margin-left: 2px;
    &:hover, &:focus, &:active {
      background-color: ${({
  theme
}) => theme.colorPinkDark};
    }
  }
  .icon {
    font-size: 25px;
    color: ${({
  theme
}) => theme.colorWhite};
  }
`;
const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  min-width: 240px;
  button {
    margin: 0;
    background-color: ${({
  theme
}) => theme.colorPinkDarker};
    border-color: ${({
  theme
}) => theme.colorWhite};
    &:hover, &:focus, &:active {
      background-color: ${({
  theme
}) => theme.colorPinkDark};
    }
  }
  a {
    ${textSmall};
    line-height: 30px;
    color: ${({
  theme
}) => theme.colorWhite};
    &:hover, &:focus, &:active {
      color: ${({
  theme
}) => theme.colorWhite};
      text-decoration: underline;
    }
  }
`;