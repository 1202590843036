// @ts-strict-ignore
import { captureException } from '@sentry/nextjs';
import actionTypes from 'src/modules/community/shared/actionTypes';
import { setAlert } from 'src/components/alert/actions';
import { closeModal, setModalProps, showModal }  from 'src/modules/shared/modal/actions';
import i18n from 'src/utils/translate';
import { pluralize } from 'src/utils/string';
import { ModalProps } from 'src/modules/shared/modal/components/defaultModal';
import { ModalType } from 'src/constants/modalTypes';
import { del, getPublic, post } from 'src/utils/api';
import { ReduxState } from 'src/store/store';
import { fetchCurrentUser, fetchPermissions } from 'src/modules/shared/context/actions';
import { fetchPost, fetchPrivatePost } from 'src/modules/community/singlePost/actions/fetchActions';

export function followCommunity({ communitySlug, successMessage }: { communitySlug?: string, successMessage: string | JSX.Element }) {
  return async (dispatch, getState: () => ReduxState) => {
    try {
      const { context: { currentCommunity, currentUser }, pages: { singlePost } } = getState();
      const slug = communitySlug ? communitySlug : currentCommunity.slug;
      await post(`private/communities/${slug}/follow/${currentUser.userId}`);

      dispatch({ type: actionTypes.FOLLOW_COMMUNITY_SUCCESS });
      dispatch(setAlert(successMessage, 'success'));

      // Update followed communities, permissions and post if on single post page
      await dispatch(fetchCurrentUser());
      if (currentCommunity?.communityId) {
        await dispatch(fetchPermissions(currentCommunity.communityId));
        if (singlePost?.id) {
          dispatch(fetchPrivatePost(singlePost.id, currentCommunity.slug));
        }
      }
    } catch (ex) {
      captureException(ex);
      dispatch(setAlert(ex.message));
      dispatch({ type: actionTypes.FOLLOW_COMMUNITY_FAILURE });
    }
  };
}

export function showUnfollowModal({ communitySlug, communityName }: { communitySlug: string, communityName: string }) {
  return (dispatch) => {
    const modalProps: ModalProps = {
      acceptButtonText: i18n.t('Leave Community'),
      cancelButtonText: i18n.t('Cancel'),
      children: i18n.t('Leaving the community will affect your news feed, emails, notifications and badges. Your contributions will remain on the community unless you delete them.'),
      title: i18n.t('Are you sure you want to do this?'),
      onAccept: () => {
        dispatch(unfollowCommunity({ communitySlug, successMessage: i18n.t('You have left %1$s').replace('%1$s', communityName) + '!' }));
        dispatch(closeModal());
      },
    };
    dispatch(showModal({ modalType: ModalType.Default, modalProps }));
  };
}

function unfollowCommunity({ communitySlug, successMessage }: { communitySlug: string, successMessage: string }) {
  return async (dispatch, getState: () => ReduxState) => {
    try {
      const { context: { currentUser, currentCommunity }, pages: { singlePost } } = getState();
      await del(`private/communities/${communitySlug}/follow/${currentUser.userId}`);

      dispatch({ type: actionTypes.UNFOLLOW_COMMUNITY_SUCCESS, communitySlug });
      dispatch(setAlert(successMessage, 'success'));

      // Update followed communities, permissions and post if on single post page
      await dispatch(fetchCurrentUser());
      if (currentCommunity?.communityId) {
        await dispatch(fetchPermissions(currentCommunity.communityId));
        if (singlePost?.id) {
          dispatch(fetchPost(singlePost.id, currentCommunity.slug));
        }
      }
    } catch (ex) {
      captureException(ex);
      dispatch({ type: actionTypes.UNFOLLOW_COMMUNITY_FAILURE });
      dispatch(setAlert(ex.message));
    }
  };
}

export const sendInvites = (communitySlug, emails) => async (dispatch) => {
  try {
    const { data: { invitesSent: successful, failedValidation: invalid } } = await post(`private/communities/${communitySlug}/invites`, { emails });

    if (successful.length > 0) {
      dispatch(setAlert(pluralize(i18n.t('%1$s invite sent!'), i18n.t('%1$s invites sent!'), successful.length), 'success'));
    }
    if (successful.length === emails.length) {
      dispatch(closeModal());
    } else {
      dispatch(setModalProps({ successful, invalid }));
    }
  } catch (ex) {
    captureException(ex);
    dispatch(setAlert(ex.message));
  }
};

export const fetchCommunityTopics = (communitySlug: string) => {
  return async (dispatch) => {
    try {
      const { data } = await getPublic(`topics/${communitySlug}`);
      const topics = data.map(topic => ({
        id: topic.topicId,
        name: topic.name,
        postCount: topic.postCount,
      }));

      dispatch({
        type: actionTypes.FETCH_COMMUNITY_TOPICS_SUCCESS,
        topics,
      });
    } catch (ex) {
      dispatch({ type: actionTypes.FETCH_COMMUNITY_TOPICS_FAILURE, error: ex });
      dispatch(setAlert(ex.message));
    }
  };
};
