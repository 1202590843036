import { useEffect, useState, useRef } from 'react';
import { Alert } from 'src/components/alert/types';

export const useHover = <T extends HTMLElement>(): [React.RefObject<T>, boolean] => {
  const [hover, setHover] = useState<boolean>(false);
  const ref = useRef<T>(null);

  const handleMouseOver = () => setHover(true);
  const handleMouseOut = () => setHover(false);

  useEffect(() => {
    const node = ref.current;
    if (node) {
      node.addEventListener('mouseenter', handleMouseOver);
      node.addEventListener('mouseleave', handleMouseOut);
      return () => {
        node.removeEventListener('mouseenter', handleMouseOver);
        node.removeEventListener('mouseleave', handleMouseOut);
      };
    }
  }, [ref.current]);

  return [ref, hover];
};

/*
 * The alert should disappear after 3 seconds. However if the user moves their
 * mouse over the alert it should not disappear while they're interacting with it.
 * Once they move the mouse out then the timeout should restart.
 */
type Props = {
  alert: Alert | null,
  unsetAlert: () => void,
}
export const useAutoDismiss = <T extends HTMLElement>({ alert, unsetAlert }: Props): React.RefObject<T> => {

  const [ref, hover] = useHover<T>();

  useEffect(() => {
    if (alert && !hover && alert.timeout !== 'none') {
      const timeout = setTimeout(unsetAlert, alert.timeout || 3000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [alert, hover, unsetAlert]);

  return ref;
};
