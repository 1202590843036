import { SyntheticEvent } from 'react';
import Script from 'next/script';
import Link from 'next/link';
import styled from 'styled-components';
import { useDispatch } from 'src/store/store';
import { logout as logoutAction } from 'src/modules/shared/auth/actions';
import i18n from 'src/utils/translate';
import { Category, useCookieCategory } from 'src/utils/cookie';
import SitebarDropdown, { Dropdown } from 'src/components/siteBar/sitebarDropdown';
import { titleSmall } from 'styles-js/mixins/typography';
import Menu from 'src/components/siteBar/icons/menu';
import Info from 'src/components/siteBar/icons/info';
import Cog from 'src/components/siteBar/icons/cog';
import Mail from 'src/components/siteBar/icons/mail';
import Paper from 'src/components/siteBar/icons/paper';
import Logout from 'src/components/siteBar/icons/logout';
import { DropdownButton, ImageContainer, LinkText } from 'src/components/siteBar/sharedStyles';
type Props = {
  isOpen: boolean;
  handleClick: (e: SyntheticEvent) => void;
  setOpenMoreMenu: (isOpen: boolean) => void;
};
export default function MoreMenu({
  isOpen,
  handleClick,
  setOpenMoreMenu
}: Props) {
  const dispatch = useDispatch();
  const [isFunctionalityAccepted] = useCookieCategory(Category.Functionality);
  return <Container data-sentry-element="Container" data-sentry-component="MoreMenu" data-sentry-source-file="desktopMoreMenu.tsx">
      {isFunctionalityAccepted &&
    // TECH-279
    <Script src="https://connect.facebook.net/en_US/sdk.js" />}
      <DropdownButton $isActive={isOpen} data-testid="sitebar-user-menu" onClick={handleClick} data-sentry-element="DropdownButton" data-sentry-source-file="desktopMoreMenu.tsx">
        <ImageContainer data-sentry-element="ImageContainer" data-sentry-source-file="desktopMoreMenu.tsx">
          <Menu data-sentry-element="Menu" data-sentry-source-file="desktopMoreMenu.tsx" />
        </ImageContainer>
        <LinkText $isActive={isOpen} data-sentry-element="LinkText" data-sentry-source-file="desktopMoreMenu.tsx">{i18n.t('Menu')}</LinkText>
      </DropdownButton>

      <SitebarDropdown dataTestId="desktop-more-menu" isOpen={isOpen} data-sentry-element="SitebarDropdown" data-sentry-source-file="desktopMoreMenu.tsx">
        <Link legacyBehavior passHref href="/settings" data-sentry-element="Link" data-sentry-source-file="desktopMoreMenu.tsx">
          <MenuLink data-testid={'sitebar-settings'} onClick={() => setOpenMoreMenu(false)} data-sentry-element="MenuLink" data-sentry-source-file="desktopMoreMenu.tsx">
            <div><Cog data-sentry-element="Cog" data-sentry-source-file="desktopMoreMenu.tsx" /></div>
            <span>{i18n.t('Account settings')}</span>
          </MenuLink>
        </Link>
        <Link legacyBehavior passHref as={'/email-notifications'} href={'/emailNotifications'} data-sentry-element="Link" data-sentry-source-file="desktopMoreMenu.tsx">
          <MenuLink data-testid={'sitebar-email-notifications'} onClick={() => setOpenMoreMenu(false)} data-sentry-element="MenuLink" data-sentry-source-file="desktopMoreMenu.tsx">
            <div><Mail data-sentry-element="Mail" data-sentry-source-file="desktopMoreMenu.tsx" /></div>
            <span>{i18n.t('Email preferences')}</span>
          </MenuLink>
        </Link>
        <Link legacyBehavior passHref as={'/your-needs'} href={'/needsAssessment/needsAssessmentList'} data-sentry-element="Link" data-sentry-source-file="desktopMoreMenu.tsx">
          <MenuLink data-testid={'sitebar-your-needs'} onClick={() => setOpenMoreMenu(false)} data-sentry-element="MenuLink" data-sentry-source-file="desktopMoreMenu.tsx">
            <div><Paper data-sentry-element="Paper" data-sentry-source-file="desktopMoreMenu.tsx" /></div>
            <span>{i18n.t('Needs assessment')}</span>
          </MenuLink>
        </Link>
        <MenuLink href="#" onClick={e => {
        e.preventDefault();
        window.HelpScoutBeacon.triggerHelpScoutBeacon('USER_SUPPORT');
      }} data-sentry-element="MenuLink" data-sentry-source-file="desktopMoreMenu.tsx">
          <div><Info data-sentry-element="Info" data-sentry-source-file="desktopMoreMenu.tsx" /></div>
          <span>{i18n.t('Get help using this site')}</span>
        </MenuLink>
        <MenuLink data-testid={'sitebar-log-out'} href="#" onClick={e => {
        e.preventDefault();
        dispatch(logoutAction());
      }} data-sentry-element="MenuLink" data-sentry-source-file="desktopMoreMenu.tsx">
          <div><Logout data-sentry-element="Logout" data-sentry-source-file="desktopMoreMenu.tsx" /></div>
          <span>{i18n.t('Log out')}</span>
        </MenuLink>
      </SitebarDropdown>
    </Container>;
}
const Container = styled.div`
  position: relative;
  ${Dropdown} {
    right: 0;
  }
`;
const MenuLink = styled.a`
  && { // precedence boost needed
    ${titleSmall}
    display: flex;
    align-items: center;
    padding: 10px 20px;
    img {
      margin-right: 0;
    }
  }

  &:hover, &:focus, &:active  {
    color: ${({
  theme
}) => theme.colorBlack};
    background-color: ${({
  theme
}) => theme.colorGreenLighter};
  }

  div {
    flex-basis: 24px;
    display: flex;
    justify-content: center;
    color: ${({
  theme
}) => theme.colorGreyDark};
  }
  span {
    margin-left: 15px;
  }
`;