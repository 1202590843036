// @ts-strict-ignore
import { useState } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import styled from 'styled-components';
import { capitalize } from 'underscore.string';
import { useSelector } from 'src/store/store';
import config from 'config/config';
import i18n from 'src/utils/translate';
import { Section } from 'src/constants/footfall';
import { footfall } from 'src/components/siteBar/sitebarFootfall';
import MobileSearchInput from 'src/components/siteBar/mobileSearchInput';
import { LinkButton } from 'styles-js/buttons';
import { Avatar, ImageContainer, ImageIcon, LinkText, TopSiteBar, StyledLink } from 'src/components/siteBar/sharedStyles';
import Icon from 'src/components/icon';
import { useBranch } from 'src/utils/cookie';
export default function MobileSiteBarTop(): JSX.Element {
  const {
    pathname
  } = useRouter();
  const {
    username,
    avatarImageUrl
  } = useSelector(({
    context
  }) => context.currentUser);
  const isOwnProfile = useSelector(({
    pages
  }) => pages.profile?.profileUser?.isOwnProfile);
  const [isSearching, setIsSearching] = useState(false);
  const isB = useBranch() === 'B';
  return <TopContainer className="js-sitebar" data-sentry-element="TopContainer" data-sentry-component="MobileSiteBarTop" data-sentry-source-file="mobileSiteBarTop.tsx">
      <Link legacyBehavior passHref href="/newsfeed" data-sentry-element="Link" data-sentry-source-file="mobileSiteBarTop.tsx">
        <StyledLink $isActive={pathname.includes('newsfeed')} $isB={isB} onClick={() => footfall(Section.HuLogo)} data-sentry-element="StyledLink" data-sentry-source-file="mobileSiteBarTop.tsx">
          <ImageContainer data-sentry-element="ImageContainer" data-sentry-source-file="mobileSiteBarTop.tsx">
            <ImageIcon alt="Home" src={`${config.cdn.logos}H-logo-speech-bubble-green.svg`} data-sentry-element="ImageIcon" data-sentry-source-file="mobileSiteBarTop.tsx" />
          </ImageContainer>
          <LinkText $isActive={pathname.includes('newsfeed')} data-sentry-element="LinkText" data-sentry-source-file="mobileSiteBarTop.tsx">{capitalize(i18n.t('home'))}</LinkText>
        </StyledLink>
      </Link>

      <MobileSearchButton aria-label="Start a search" onClick={() => setIsSearching(true)} data-sentry-element="MobileSearchButton" data-sentry-source-file="mobileSiteBarTop.tsx">
        {i18n.t('Search HealthUnlocked')}
        <Icon icon="search" data-sentry-element="Icon" data-sentry-source-file="mobileSiteBarTop.tsx" />
      </MobileSearchButton>

      <Link legacyBehavior passHref as={`/user/${username}`} href={`/user/profile?username=${username}`} data-sentry-element="Link" data-sentry-source-file="mobileSiteBarTop.tsx">
        <StyledLink $isActive={pathname.includes('user') && isOwnProfile} $isB={isB} onClick={() => footfall(Section.More)} data-sentry-element="StyledLink" data-sentry-source-file="mobileSiteBarTop.tsx">
          <ImageContainer data-sentry-element="ImageContainer" data-sentry-source-file="mobileSiteBarTop.tsx">
            <Avatar alt="Your avatar" src={avatarImageUrl} data-sentry-element="Avatar" data-sentry-source-file="mobileSiteBarTop.tsx" />
          </ImageContainer>
          <LinkText $isActive={pathname.includes('user') && isOwnProfile} data-sentry-element="LinkText" data-sentry-source-file="mobileSiteBarTop.tsx">{capitalize(i18n.t('profile'))}</LinkText>
        </StyledLink>
      </Link>

      {isSearching && <SearchContainer>
          <MobileSearchInput onCancel={() => setIsSearching(false)} />
        </SearchContainer>}
    </TopContainer>;
}
const TopContainer = styled(TopSiteBar)`
  display: flex;
  align-items: center;
`;
const MobileSearchButton = styled(LinkButton)`
  && { // precedence boost needed
    flex-grow: 1;
    min-width: 110px;
    padding: 0 10px;
    margin: 0 5px;
    line-height: 35px;
    border-radius: 4px;
    background-color: ${({
  theme
}) => theme.colorBlueBackground};
    color: ${({
  theme
}) => theme.colorTextBlack};
    font-size: ${({
  theme
}) => theme.fontSizeXs};
    text-align: left;
    &:hover, &:focus, &:active {
      color: ${({
  theme
}) => theme.colorGreyDark};
    }
  }
  .icon {
    float: right;
    height: 35px;
    font-size: 17px;
    color: ${({
  theme
}) => theme.colorTextBlack};
  }
`;
const SearchContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1006;
  background-color: ${({
  theme
}) => theme.colorWhite};
`;