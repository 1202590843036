import styled, { css } from 'styled-components';
import SearchBar from 'src/components/siteBar/searchBar';
import { titleSmall } from 'styles-js/mixins/typography';
import { SearchBarIconButton, LinkButton } from 'styles-js/buttons';

const SiteBar = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  z-index: 1049;
  background-color: ${({ theme }) => theme.colorWhite};
`;
export const TopSiteBar = styled(SiteBar)`
  top: 0;
  border-bottom: ${({ theme }) => theme.greyLine};
  height: ${({ theme }) => theme.navHeightTop};
`;
export const BottomSiteBar = styled(SiteBar)`
  bottom: 0;
  border-top: ${({ theme }) => theme.greyLine};
  height: ${({ theme }) => theme.navHeightBottom};
`;

export const LinkText = styled.span<{ $isActive: boolean }>`
  ${titleSmall};
  font-size: 10px;
  line-height: 19px;
  font-weight: ${({ theme }) => theme.fontWeightBoldTitle};
  color: ${({ theme, $isActive }) => $isActive ? theme.colorBlack : theme.colorGreyDark};
`;

export const LinkStyles = css`
  display: flex;
  width: 54px;
  height: 100%;
  align-items: center;
  flex-direction: column;
  color: ${({ theme }) => theme.colorGreyDark};

  &:hover, &:focus, &:active {
    background-color: ${({ theme }) => theme.colorGreenLighter};
    color: ${({ theme }) => theme.colorBlack};
    ${LinkText} {
      color: ${({ theme }) => theme.colorBlack};
    }
  }
`;

const LinkStylesB = css`
  ${LinkStyles}
  &:hover, &:focus, &:active {
    background-color: ${({ theme }) => theme.colorBlueBackground};
    color: ${({ theme }) => theme.colorGreyDark};
    ${LinkText} {
      color: ${({ theme }) => theme.colorGreyDark};
    }
  }
`;

export const StyledLink = styled.a<{ $isActive: boolean, $isB?: boolean }>`
  ${({ $isB }) => $isB ? LinkStylesB : LinkStyles };
  ${({ $isActive }) => $isActive && css`
    background-color: ${({ theme }) => theme.colorGreenLighter};
  `};
  ${({ $isActive, $isB }) => $isActive && $isB && css`
    background-color: ${({ theme }) => theme.colorBlueBackground};
  `};
`;

export const ImageContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 30px;
  align-items: center;
  justify-content: center;
  padding-top: 6px;
`;

export const ImageIcon = styled.img`
  width: 22px;
  height: 22px;
`;

export const Avatar = styled(ImageIcon)`
  border-radius: 50%;
`;

export const StyledSearchBar = styled(SearchBar)`
  align-items: center;
  height: 49px;
  margin-bottom: 0;

  .search-bar__container {
    height: 36px;
    margin-bottom: 0;
  }

  .search-bar__input {
    font-size: 16px; //if less than 16px, Safari auto-zooms on forms on iPhone
  }

  ${SearchBarIconButton} {
    width: 40px;
    font-size: 18px;
  }

  .react-autosuggest__suggestions-container {
    margin-top: -5px;
  }
`;

export const Counter = styled.div`
  position: absolute;
  top: 12px;
  right: 38%;
  min-width: 20px;
  height: 20px;
  margin-right: -20px;
  padding: 0 5px;
  background-color: ${({ theme }) => theme.colorYellow};
  color: ${({ theme }) => theme.colorBlack};
  border-radius: 10px;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
`;

export const DropdownButton = styled(LinkButton)<{ $isActive: boolean }>`
  ${LinkStyles};
  width: 60px;
  border-radius: 0;
  transition: none;
  ${({ $isActive }) => $isActive && css`
    background-color: ${({ theme }) => theme.colorGreenLighter};
  `};
`;
