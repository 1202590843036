// called write so that it can include anything write-action related

export const MIN_CHARS_EDITOR = 5;

export const MIN_CHARS_REPLY = 2;
export const MIN_CHARS_CHAT_MESSAGE = 1;

export const MAX_CHARS_POST_TITLE = 140;

export const MAX_CHARS_POST_BODY = 33000;
export const MAX_CHARS_REPLY_BODY = 7000;

export const HOTKEYS = { // undo + redo shortcuts work too thanks to withHistory
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
};

export const NAV_KEYS = ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'];

export const MAX_MENTIONS = 15;
export const MAX_MENTION_SUGGESTIONS = 5;
