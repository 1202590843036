// @ts-strict-ignore
import { captureException } from '@sentry/nextjs';
import actionTypes from 'src/modules/community/singlePost/actionTypes';
import { enrichDataObject } from 'src/utils/enrichDataObject';
import { get, getPublic, getServerSideConfig } from 'src/utils/api';
import { setAlert } from 'src/components/alert/actions';
import { MAX_POSTS_WIDGET } from 'src/constants/widgets';
import { ReduxState } from 'src/store/store';

export const fetchPost = (postId: string, communitySlug: string) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.FETCH_POST });
    try {
      const { data } = await getPublic(`posts/${communitySlug}/${postId}`);
      const post = enrichDataObject(data, data.mentionedUsers);
      const responses = (post.responses || []).map(response => enrichDataObject(response, data.mentionedUsers));
      dispatch({
        type: actionTypes.FETCH_POST_SUCCESS,
        post: {
          ...post,
          responses,
        },
      });
    } catch (ex) {
      captureException(ex);
      dispatch({ type: actionTypes.FETCH_POST_FAILURE, error: ex });
      dispatch(setAlert(ex.response?.data?.message));
    }
  };
};

export const fetchPrivatePost = (postId: string, communitySlug: string) => {
  return async (dispatch, getState: () => ReduxState) => {
    dispatch({ type: actionTypes.FETCH_POST });
    try {
      const { data } = await get(`private/posts/${communitySlug}/${postId}`, getServerSideConfig(getState().context.sessionId));
      const post = enrichDataObject(data, data.mentionedUsers);
      const responses = (post.responses || []).map(response => enrichDataObject(response, data.mentionedUsers));
      dispatch({
        type: actionTypes.FETCH_POST_SUCCESS,
        post: {
          ...post,
          responses,
        },
      });
    } catch (ex) {
      captureException(ex);
      dispatch({ type: actionTypes.FETCH_POST_FAILURE, error: ex });
      dispatch(setAlert(ex.response?.data?.message));
    }
  };
};

export const fetchPopularPosts = (communitySlug: string) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.FETCH_POPULAR_POSTS });
    try {
      const { data } = await getPublic(`posts/${communitySlug}/popular`);
      const posts = data.slice(0, MAX_POSTS_WIDGET).map(post => ({
        postId: post.postId,
        title: post.title,
        userImageHash: post.author.userImageHash,
        username: post.author.username,
        postTypeId: post.postTypeId,
      }));
      dispatch({
        type: actionTypes.FETCH_POPULAR_POSTS_SUCCESS,
        posts,
      });
    } catch (ex) {
      captureException(ex);
      dispatch({ type: actionTypes.FETCH_POPULAR_POSTS_FAILURE, error: ex });
    }
  };
};

export const fetchPinnedPosts = (communitySlug: string) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.FETCH_PINNED_POSTS });
    try {
      const { data } = await getPublic(`posts/${communitySlug}/pinned`);
      const posts = data.slice(0, MAX_POSTS_WIDGET + 1).map(post => ({ // get 6 posts so we can pin/unpin a post in SPP, and update the pinnedPost widget
        postId: post.postId,
        title: post.title,
        userImageHash: post.author.userImageHash,
        username: post.author.username,
        postTypeId: post.postTypeId,
      }));
      dispatch({
        type: actionTypes.FETCH_PINNED_POSTS_SUCCESS,
        posts,
      });
    } catch (ex) {
      captureException(ex);
      dispatch({ type: actionTypes.FETCH_PINNED_POSTS_FAILURE, error: ex });
    }
  };
};

export const fetchPrivatePinnedPosts = (communitySlug: string) => {
  return async (dispatch, getState: () => ReduxState) => {
    dispatch({ type: actionTypes.FETCH_PINNED_POSTS });
    try {
      const { data } = await get(`private/posts/${communitySlug}/pinned`, getServerSideConfig(getState().context.sessionId));
      const posts = data.slice(0, MAX_POSTS_WIDGET + 1).map(post => ({ // get 6 so we can update pinnedPost widget on unpin
        postId: post.postId,
        title: post.title,
        userImageHash: post.author.userImageHash,
        username: post.author.username,
        postTypeId: post.postTypeId,
      }));
      dispatch({
        type: actionTypes.FETCH_PINNED_POSTS_SUCCESS,
        posts,
      });
    } catch (ex) {
      captureException(ex);
      dispatch({ type: actionTypes.FETCH_PINNED_POSTS_FAILURE, error: ex });
    }
  };
};

export const fetchRelatedPosts = (postId: string, communitySlug: string) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.FETCH_RELATED_POSTS });
    try {
      const { data } = await getPublic(`posts/${communitySlug}/${postId}/related`);
      dispatch({
        type: actionTypes.FETCH_RELATED_POSTS_SUCCESS,
        posts: data,
      });
    } catch (ex) {
      captureException(ex);
      dispatch({ type: actionTypes.FETCH_RELATED_POSTS_FAILURE });
    }
  };
};

export const fetchPrivateRelatedPosts = (postId: string, communitySlug: string) => {
  return async (dispatch, getState: () => ReduxState) => {
    dispatch({ type: actionTypes.FETCH_RELATED_POSTS });
    try {
      const { data } = await get(`private/posts/${communitySlug}/${postId}/related`, getServerSideConfig(getState().context.sessionId));
      dispatch({
        type: actionTypes.FETCH_RELATED_POSTS_SUCCESS,
        posts: data,
      });
    } catch (ex) {
      captureException(ex);
      dispatch({ type: actionTypes.FETCH_RELATED_POSTS_FAILURE });
    }
  };
};

export const fetchNextPosts = (postId: string, date: string, communitySlug: string) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.FETCH_NEXT_POSTS });
    try {
      const { data } = await getPublic(`posts/${communitySlug}/${postId}/next`, {
        params: {
          pageSize: 30,
          CreatedBefore: date,
        },
      });
      dispatch({
        type: actionTypes.FETCH_NEXT_POSTS_SUCCCESS,
        posts: data,
      });
    } catch (ex) {
      dispatch({ type: actionTypes.FETCH_NEXT_POSTS_FAILURE, error: ex });
    }
  };
};

export const fetchPrivateNextPosts = (postId: string, date: string, communitySlug: string) => {
  return async (dispatch, getState: () => ReduxState) => {
    dispatch({ type: actionTypes.FETCH_NEXT_POSTS });
    try {
      const { data } = await get(`private/posts/${communitySlug}/${postId}/next`, {
        params: {
          pageSize: 30,
          CreatedBefore: date,
        },
        ...getServerSideConfig(getState().context.sessionId),
      });
      dispatch({
        type: actionTypes.FETCH_NEXT_POSTS_SUCCCESS,
        posts: data,
      });
    } catch (ex) {
      dispatch({ type: actionTypes.FETCH_NEXT_POSTS_FAILURE, error: ex });
    }
  };
};
