import Link from 'next/link';
import { useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'src/store/store';
import i18n from 'src/utils/translate';
import config from 'config/config';
import { showSignupModal, showLoginModal } from 'src/modules/shared/modal/actions';
import { trackFootfallEvent, trackFootfallEventWithHrefDelay } from 'src/utils/footfall';
import { Event, Section } from 'src/constants/footfall';
import Button from 'src/components/buttons/button';
import SearchBar from 'src/components/siteBar/searchBar';
import MobileSearchInput from 'src/components/siteBar/mobileSearchInput';
import { textLinkSecondary } from 'styles-js/mixins/typography';
import { LinkButton, SearchBarIconButton } from 'styles-js/buttons';
import Icon from 'src/components/icon';
export default function VisitorSiteBar(): JSX.Element {
  const dispatch = useDispatch();
  const [isSearching, setIsSearching] = useState(false);
  return <SiteBar aria-label="main navigation" data-sentry-element="SiteBar" data-sentry-component="VisitorSiteBar" data-sentry-source-file="visitorSiteBar.tsx">
      <SiteBarInner data-sentry-element="SiteBarInner" data-sentry-source-file="visitorSiteBar.tsx">
        <SitebarActionsLeft data-sentry-element="SitebarActionsLeft" data-sentry-source-file="visitorSiteBar.tsx">
          <LogoContainer itemScope={true} itemType="https://schema.org/Organization" data-sentry-element="LogoContainer" data-sentry-source-file="visitorSiteBar.tsx">
            <Link as="/" href="/" itemProp="url" onClick={() => {
            trackFootfallEvent(Event.Clicked, {
              clickMetadata: {
                section: Section.HuLogo
              },
              clickType: 'visitor-sitebar'
            });
          }} data-sentry-element="Link" data-sentry-source-file="visitorSiteBar.tsx">
              <DesktopLogo alt="HealthUnlocked logo" height="18" itemProp="logo" src={`${config.cdn.logos}healthunlocked.svg`} width="188" data-sentry-element="DesktopLogo" data-sentry-source-file="visitorSiteBar.tsx" />
              <MobileLogo alt="HealthUnlocked" height="32" src={`${config.cdn.logos}H-logo-speech-bubble-green.svg`} width="32" data-sentry-element="MobileLogo" data-sentry-source-file="visitorSiteBar.tsx" />
            </Link>
          </LogoContainer>

          <MobileSearchButton aria-label="Start a search" onClick={() => setIsSearching(true)} data-sentry-element="MobileSearchButton" data-sentry-source-file="visitorSiteBar.tsx">
            {i18n.t('Search')}
            <Icon icon="search" data-sentry-element="Icon" data-sentry-source-file="visitorSiteBar.tsx" />
          </MobileSearchButton>

          <SearchBar className="hidden-xxs" onClick={() => {
          trackFootfallEvent(Event.Clicked, {
            clickMetadata: {
              section: Section.SearchBar
            },
            clickType: 'visitor-sitebar'
          });
        }} data-sentry-element="SearchBar" data-sentry-source-file="visitorSiteBar.tsx" />
        </SitebarActionsLeft>

        <SitebarActionsRight data-sentry-element="SitebarActionsRight" data-sentry-source-file="visitorSiteBar.tsx">
          <A className="hidden-xs" href="https://about.healthunlocked.com" onClick={e => {
          e.preventDefault();
          trackFootfallEventWithHrefDelay('https://about.healthunlocked.com', Event.Clicked, {
            clickMetadata: {
              section: Section.AboutUs
            },
            clickType: 'visitor-sitebar'
          });
        }} data-sentry-element="A" data-sentry-source-file="visitorSiteBar.tsx">
            {i18n.t('About')}
          </A>

          <A data-testid="visitor-sitebar-login-button" href="/login" id="sitebar-login-button" onClick={event => {
          event.preventDefault();
          trackFootfallEvent(Event.Clicked, {
            clickMetadata: {
              section: Section.Login
            },
            clickType: 'visitor-sitebar'
          });
          dispatch(showLoginModal());
        }} data-sentry-element="A" data-sentry-source-file="visitorSiteBar.tsx">
            {i18n.t('Log in')}
          </A>

          <JoinButton dataTestId="visitor-sitebar-signup-button" id="sitebar-signup-button" text={i18n.t('Join')} onClick={() => {
          trackFootfallEvent(Event.Clicked, {
            clickMetadata: {
              section: Section.Join
            },
            clickType: 'visitor-sitebar'
          });
          dispatch(showSignupModal());
        }} data-sentry-element="JoinButton" data-sentry-source-file="visitorSiteBar.tsx" />
        </SitebarActionsRight>
      </SiteBarInner>

      {isSearching && <SearchContainer>
          <MobileSearchInput onCancel={() => setIsSearching(false)} />
        </SearchContainer>}
    </SiteBar>;
}
const SiteBar = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: ${({
  theme
}) => theme.navHeightTop};
  line-height: ${({
  theme
}) => theme.navHeightTop};
  background-color: ${props => props.theme.colorWhite};
  z-index: ${props => props.theme.zindexLiftTop};
  border-bottom: ${props => props.theme.greyLine};
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  .search-bar {
    width: 225px;
    margin-bottom: 0;
  }
  .search-bar__container {
    height: 36px;
    display: inline-flex;
    flex: 1;
  }
  .search-bar__input {
    font-size: ${props => props.theme.fontSizeXs};
    width: 180px;
  }
  .react-autosuggest__suggestions-container {
    width: 100%;
    margin-top: 1px;
  }
  ${SearchBarIconButton} {
    width: 42px;
    font-size: 18px;
  }
`;
const SiteBarInner = styled.div`
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  @media (${props => props.theme.underScreenSmall}) {
    padding: 0 15px;
  }
`;
const SitebarActionsLeft = styled.div`
  float: left;
  display: flex;
  align-items: center;
  height: ${({
  theme
}) => theme.navHeightTop};
`;
const SitebarActionsRight = styled.div`
  float: right;
`;
const LogoContainer = styled.div`
  a {
    display: flex;
    align-items: center;
    height: 49px;
    padding-right: 16px;
    @media (${props => props.theme.underScreenSmall}) {
      padding-right: 6px;
    }
  }
`;
const DesktopLogo = styled.img`
  @media (${props => props.theme.underScreenSmall}) {
    display: none;
  }
`;
const MobileLogo = styled.img`
  margin-left: -8px;
  @media (${props => props.theme.overScreenSmall}) {
    display: none;
  }
`;
const MobileSearchButton = styled(LinkButton)`
  && { // precedence boost needed
    min-width: 110px;
    padding: 0 10px;
    line-height: 35px;
    border-radius: 4px;
    background-color: ${props => props.theme.colorBlueBackground};
    color: ${props => props.theme.colorTextBlack};
    font-size: ${props => props.theme.fontSizeXs};
    text-align: left;
    @media (${props => props.theme.overScreenXsmall}) {
      display: none;
    }
    &:hover, &:focus, &:active {
      color: ${props => props.theme.colorGreyDark};
    }
  }
  .icon {
    float: right;
    height: 35px;
    font-size: 17px;
    color: ${props => props.theme.colorTextBlack};
  }
`;
const SearchContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1006;
  background-color: ${props => props.theme.colorWhite};
`;
const A = styled.a`
  ${textLinkSecondary};
  margin-left: 15px;
`;
const JoinButton = styled(Button)`
  && { // precedence boost needed
    margin: -4px 0 0 15px;
  }
`;