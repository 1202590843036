// @ts-strict-ignore
import { captureException } from '@sentry/nextjs';
import { setAlert } from 'src/components/alert/actions';
import actionTypes from 'src/modules/user/profile/actionTypes';
import contextActionTypes from 'src/modules/shared/context/actionTypes';
import { del, get, getServerSideConfig, post } from 'src/utils/api';
import { PostType, ProfileTab } from 'src/constants/types';
import { ReduxState } from 'src/store/store';

export const fetchProfileForUsername = (username: string) => {
  return async (dispatch, getState: () => ReduxState) => {
    dispatch({ type: actionTypes.FETCH_USER_PROFILE });
    try {
      const { data } = await get(`private/user/profile/${username}`, getServerSideConfig(getState().context.sessionId));
      return dispatch({
        type: actionTypes.FETCH_USER_PROFILE_SUCCESS,
        data,
      });
    } catch (ex) {
      captureException(ex);
      dispatch({ type: actionTypes.FETCH_USER_PROFILE_FAILURE, error: ex });
    }
  };
};

export const fetchUserPosts = (userId: number, postTypeId: number, createdBeforePostId = null) => {
  return async (dispatch, getState: () => ReduxState) => {
    dispatch({ type: postTypeId === PostType.Reply ? actionTypes.FETCH_USER_REPLIES : actionTypes.FETCH_USER_POSTS });
    try {
      const { data } = await get(`private/posts/${userId}`, { params: { postTypeId, createdBeforePostId }, ...getServerSideConfig(getState().context.sessionId) });

      dispatch({
        type: postTypeId === PostType.Reply ? actionTypes.FETCH_USER_REPLIES_SUCCESS : actionTypes.FETCH_USER_POSTS_SUCCESS,
        data,
        createdBeforePostId,
      });
    } catch (ex) {
      captureException(ex);
      dispatch({ type: postTypeId === PostType.Reply ? actionTypes.FETCH_USER_REPLIES_FAILURE : actionTypes.FETCH_USER_POSTS_FAILURE });
    }
  };
};

export const followUser = (userToFollowId: number, successMessage: string) => {
  return async (dispatch, getState: () => ReduxState) => {
    const state = getState();
    const profileUser = state.pages.profile.profileUser;
    const isProfileUserFollowed = userToFollowId === profileUser.userId;
    dispatch({ type: actionTypes.FOLLOW_USER });
    try {
      const { data } = await post(`private/users/${userToFollowId}/follow`);
      if (data.success === false) {
        dispatch(setAlert());
      } else {
        const totalFollowers = profileUser.totalFollowers || 0;
        const totalFollowing = profileUser.totalFollowing || 0;
        dispatch({
          type: actionTypes.FOLLOW_USER_SUCCESS,
          data: {
            followers: isProfileUserFollowed ? (totalFollowers + 1) : totalFollowers,
            following: profileUser.isOwnProfile ? (totalFollowing + 1) : totalFollowing,
            isFollowedByCurrentUser: isProfileUserFollowed ? true : profileUser.isFollowedByCurrentUser,
          },
        });
        dispatch(setAlert(successMessage, 'success'));
        dispatch({
          type: contextActionTypes.GET_FOLLOWED_USERS,
          followedUserIds: data.followedUserIds,
        });
      }
    } catch (ex) {
      captureException(ex);
      dispatch(setAlert(ex.message));
      dispatch({ type: actionTypes.FOLLOW_USER_FAILURE, error: ex });
    }
  };
};

export const unfollowUser = (userToUnfollowId: number, successMessage: string) => {
  return async (dispatch, getState: () => ReduxState) => {
    const state = getState();
    const profileUser = state.pages.profile.profileUser;
    const isProfileUserUnfollowed = userToUnfollowId === profileUser.userId;
    dispatch({ type: actionTypes.UNFOLLOW_USER });
    try {
      const { data } = await del(`private/users/${userToUnfollowId}/follow`);

      if (data.success === false) {
        dispatch(setAlert());
      } else {
        const totalFollowers = profileUser.totalFollowers || 1;
        const totalFollowing = profileUser.totalFollowing || 1;

        dispatch({
          type: actionTypes.UNFOLLOW_USER_SUCCESS,
          data: {
            followers: isProfileUserUnfollowed ? (totalFollowers - 1) : profileUser.totalFollowers || 0,
            following: profileUser.isOwnProfile ? (totalFollowing - 1) : profileUser.totalFollowing || 0,
            isFollowedByCurrentUser: isProfileUserUnfollowed ? false : profileUser.isFollowedByCurrentUser,
          },
        });
        dispatch(setAlert(successMessage, 'success'));
        dispatch({
          type: contextActionTypes.GET_FOLLOWED_USERS,
          followedUserIds: data.followedUserIds,
        });
      }
    } catch (ex) {
      captureException(ex);
      dispatch({ type: actionTypes.UNFOLLOW_USER_FAILURE, error: ex });
      dispatch(setAlert(ex.message));
    }
  };
};

export const fetchConnections = (userId: number, filter: string, pageNumber = 1) => {
  return async (dispatch, getState: () => ReduxState) => {
    dispatch({ type: actionTypes.FETCH_CONNECTIONS });
    try {
      const { data } = await get(`private/users/${userId}/${filter}`, { params: { pageNumber }, ...getServerSideConfig(getState().context.sessionId) });
      dispatch({
        type: actionTypes.FETCH_CONNECTIONS_SUCCESS,
        data: { data, filter, pageNumber },
      });
    } catch (ex) {
      captureException(ex);
      dispatch({ type: actionTypes.FETCH_CONNECTIONS_FAILURE, error: ex });
      dispatch(setAlert(ex.message));
    }
  };
};

export const setCurrentTab = (currentTab: ProfileTab) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.SET_CURRENT_TAB,
      currentTab,
    });
  };
};

export const muteUser = (currentUserId: number, mutedUserId: number) => {
  return post(`private/users/${currentUserId}/mute-user`, { mutedUserId });
};

export const unmuteUser = (currentUserId: number, mutedUserId: number) => {
  return async (dispatch) => {
    try {
      await post(`private/users/${currentUserId}/unmute-user`, { mutedUserId });
      dispatch({ type: actionTypes.UNMUTE_USER_SUCCESS });
    } catch (ex) {
      captureException(ex);
      dispatch({ type: actionTypes.UNMUTE_USER_FAILURE, error: ex });
      dispatch(setAlert());
    }
  };
};
