// @ts-strict-ignore
import { useEffect, useId, useState } from 'react';
import classNames from 'classnames';
import Autosuggest from 'react-autosuggest';
import { throttle } from 'lodash';
import { useDispatch, useSelector } from 'src/store/store';
import { performSearch } from 'src/utils/router';
import i18n from 'src/utils/translate';
import { SearchBarIconButton } from 'styles-js/buttons';
import SearchBarInput, { useFocus } from 'src/modules/search/shared/components/searchBarInput';
import SearchBarSuggestion from 'src/modules/search/shared/components/searchBarSuggestion';
import Icon from 'src/components/icon';
import { getPublic } from 'src/utils/api';
type Props = {
  autoFocus?: boolean;
  className?: string;
  disableAutosuggest?: boolean;
  isCommunitySearch?: boolean;
  placeholder?: string;
  searchType?: 'posts' | 'members' | 'communities';
  trackingOnSubmit?: (query: string) => void;
};
export default function SearchBar({
  autoFocus = false,
  className,
  disableAutosuggest = false,
  isCommunitySearch = false,
  placeholder,
  searchType = 'posts',
  trackingOnSubmit = () => {}
}: Props) {
  const dispatch = useDispatch();
  const currentCommunity = useSelector(({
    context
  }) => context.currentCommunity);
  const searchQuery = decodeURIComponent(useSelector(({
    pages
  }) => pages?.search?.searchQuery) || '');
  const {
    isFocus,
    setIsFocus,
    inputRef
  } = useFocus({
    isAutoFocus: autoFocus && !searchQuery
  });
  const [query, setQuery] = useState(searchQuery);
  const [suggestions, setSuggestions] = useState([]);
  const id = useId();
  useEffect(() => {
    setQuery(searchQuery);
  }, [searchQuery, dispatch]);
  const communityId = isCommunitySearch ? currentCommunity.communityId : 'all';
  const communitySlug = isCommunitySearch ? currentCommunity.slug : null;
  const handleSubmit = e => {
    e.preventDefault();
    performSearch(query, String(communityId), communitySlug, searchType);
    setIsFocus(false);
    trackingOnSubmit(query);
  };
  const onSuggestionsFetchRequested = throttle(async ({
    value = ''
  }) => {
    if (value.length <= 1) return;
    const {
      data
    } = await getPublic(`search/autocomplete?q=${value}`);
    setSuggestions(data.slice(0, 10).map(x => x.toLowerCase()));
  }, 500);
  const onSuggestionSelected = (_, {
    suggestion
  }) => {
    setQuery(suggestion);
    performSearch(suggestion, String(communityId), communitySlug, searchType);
    setIsFocus(false);
  };
  return <div className={classNames({
    'search-bar': true,
    [className]: !!className
  })} data-sentry-component="SearchBar" data-sentry-source-file="searchBar.tsx">
      <form className="search-bar__form" data-testid="search-page-bar" onSubmit={handleSubmit}>
        {disableAutosuggest ? <label className={classNames({
        'search-bar__container': true,
        'search-bar__container--focused': isFocus
      })}>
              <input aria-label={i18n.t('Start a search')} className="search-bar__input" data-testid="search-bar-input" placeholder={placeholder} ref={inputRef} type="text" value={query} onBlur={() => setIsFocus(false)} onChange={e => setQuery(e.target.value)} onFocus={() => setIsFocus(true)} />
              <SearchBarIconButton disabled={!query} type="submit">
                <Icon icon="search" />
                <span className="search-bar__submit-text">{i18n.t('Search')}</span>
              </SearchBarIconButton>
            </label> : <Autosuggest getSuggestionValue={suggestion => suggestion} id={id} inputProps={{
        className: 'search-bar__input',
        placeholder,
        onChange: (_, {
          newValue
        }) => setQuery(newValue),
        value: query,
        type: 'text',
        onBlur: () => setIsFocus(false),
        onFocus: () => setIsFocus(true)
      }} renderInputComponent={renderInputComponentProps => <SearchBarInput inputRef={inputRef} isFocus={isFocus} query={query} renderInputComponentProps={renderInputComponentProps} />} renderSuggestion={text => <SearchBarSuggestion query={query} text={text} />} suggestions={suggestions} onSuggestionsClearRequested={() => setSuggestions([])} onSuggestionSelected={onSuggestionSelected} onSuggestionsFetchRequested={onSuggestionsFetchRequested} />}
      </form>
    </div>;
}