export const hideWriteCTA = [
  'blog',
  'cancer-focus-group',
  'cancer-health-insurance-focus-group',
  'news-updates',
  'nhsengland',
  'positivewellbeing',
  'pss-research',
  'pss-research2',
  'research',
  'stress-sleep-research',
];

export const hideImageUpload = [
  'menshealth-penishealth',
  'theibsnetwork',
];
