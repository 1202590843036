import styled from 'styled-components';
import i18n from 'src/utils/translate';
import { helpScoutArticle } from 'src/utils/helpScoutBeacon';
import { LinkButton } from 'styles-js/buttons';
type Props = {
  communityName: string;
};
export default function FollowSuccessMessage({
  communityName
}: Props) {
  return <span data-sentry-component="FollowSuccessMessage" data-sentry-source-file="followSuccessMessage.tsx">
      {i18n.t("You're now a member of")}{' '}{communityName}{'. '}
      <StyledLinkButton className="learn-more-link" onClick={() => helpScoutArticle('39-what-does-following-a-community-mean')} data-sentry-element="StyledLinkButton" data-sentry-source-file="followSuccessMessage.tsx">
        {i18n.t('Learn more')}
      </StyledLinkButton>
    </span>;
}
const StyledLinkButton = styled(LinkButton)`
  color: inherit;
  text-decoration: underline;
`;