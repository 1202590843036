import Script from 'next/script';
import Link from 'next/link';
import styled from 'styled-components';
import { useDispatch } from 'src/store/store';
import { logout as logoutAction } from 'src/modules/shared/auth/actions';
import i18n from 'src/utils/translate';
import { Category, useCookieCategory } from 'src/utils/cookie';
import { titleSmall } from 'styles-js/mixins/typography';
import Cog from 'src/components/siteBar/icons/cog';
import Mail from 'src/components/siteBar/icons/mail';
import Paper from 'src/components/siteBar/icons/paper';
import Info from 'src/components/siteBar/icons/info';
import Logout from 'src/components/siteBar/icons/logout';
export default function MobileMoreMenu({
  isOpen,
  setIsMenuOpen
}: {
  isOpen: boolean;
  setIsMenuOpen: (bool: boolean) => void;
}) {
  const dispatch = useDispatch();
  const [isFunctionalityAccepted] = useCookieCategory(Category.Functionality);
  return <>
      {isFunctionalityAccepted &&
    // TECH-279
    <Script src="https://connect.facebook.net/en_US/sdk.js" />}
      {isOpen && <Menu data-testid="mobile-more-menu">
          <>
            <Link legacyBehavior passHref href="/settings">
              <MenuLink onClick={() => setIsMenuOpen(false)}>
                <div><Cog /></div>
                <span>{i18n.t('Account settings')}</span>
              </MenuLink>
            </Link>
            <Link legacyBehavior passHref as={'/email-notifications'} href={'/emailNotifications'}>
              <MenuLink onClick={() => setIsMenuOpen(false)}>
                <div><Mail /></div>
                <span>{i18n.t('Email preferences')}</span>
              </MenuLink>
            </Link>
            <Link legacyBehavior passHref as={'/your-needs'} href={'/needsAssessment/needsAssessmentList'}>
              <MenuLink onClick={() => setIsMenuOpen(false)}>
                <div><Paper /></div>
                <span>{i18n.t('Needs assessment')}</span>
              </MenuLink>
            </Link>
            <MenuLink href="#" onClick={e => {
          e.preventDefault();
          window.HelpScoutBeacon.triggerHelpScoutBeacon('USER_SUPPORT');
        }}>
              <div><Info /></div>
              <span>{i18n.t('Get help using this site')}</span>
            </MenuLink>
            <MenuLink href="#" onClick={e => {
          e.preventDefault();
          dispatch(logoutAction());
        }}>
              <div><Logout /></div>
              <span>{i18n.t('Log out')}</span>
            </MenuLink>
          </>
        </Menu>}
    </>;
}
const Menu = styled.nav`
  position: fixed;
  bottom: ${({
  theme
}) => theme.navHeightBottom};
  right: 0;
  left: 0;
  z-index: 1049;
  background-color: ${({
  theme
}) => theme.colorWhite};
  box-shadow: 0 -3px 6px ${({
  theme
}) => theme.colorShadow};
  margin: 0;
  padding: 0;
  list-style: none;
  transition: opacity 0.1s ease-out, visibility 0s 0.1s;
`;
const MenuLink = styled.a`
  ${titleSmall};
  display: flex;
  align-items: center;
  padding: 18px 20px;

  &:hover, &:focus, &:active  {
    color: ${({
  theme
}) => theme.colorBlack};
    background-color: ${({
  theme
}) => theme.colorGreenLighter};
  }

  div {
    flex-basis: 24px;
    display: flex;
    justify-content: center;
    color: ${({
  theme
}) => theme.colorGreyDark};
  }
  span {
    margin-left: 15px;
  }
`;