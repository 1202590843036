import { SyntheticEvent } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import { useSelector } from 'src/store/store';
import i18n from 'src/utils/translate';
import SitebarDropdown, { Divider, Dropdown, SitebarDropdownLink } from 'src/components/siteBar/sitebarDropdown';
import NotificationList from 'src/components/siteBar/notificationList';
import Loader from 'src/components/loader';
import { text } from 'styles-js/mixins/typography';
import Bell from 'src/components/siteBar/icons/bell';
import { Counter, DropdownButton, ImageContainer, LinkText } from 'src/components/siteBar/sharedStyles';
const NOTIFICATION_MENU_LIMIT = 5;
type Props = {
  isOpen: boolean;
  handleClick: (e: SyntheticEvent) => void;
  setOpenNotifications: (isOpen: boolean) => void;
};
export default function NotificationMenu({
  isOpen,
  handleClick,
  setOpenNotifications
}: Props): JSX.Element {
  const {
    pathname
  } = useRouter();
  const {
    isLoading,
    items,
    unreadNotificationsCount
  } = useSelector(({
    context
  }) => context.notifications) ?? {};
  return <Container data-sentry-element="Container" data-sentry-component="NotificationMenu" data-sentry-source-file="notificationMenu.tsx">
      <DropdownButton $isActive={isOpen || pathname.includes('notifications')} data-testid="sitebar-alerts" onClick={handleClick} data-sentry-element="DropdownButton" data-sentry-source-file="notificationMenu.tsx">
        <ImageContainer data-sentry-element="ImageContainer" data-sentry-source-file="notificationMenu.tsx">
          <Bell data-sentry-element="Bell" data-sentry-source-file="notificationMenu.tsx" />
          {unreadNotificationsCount > 0 && <Counter>{unreadNotificationsCount}</Counter>}
        </ImageContainer>
        <LinkText $isActive={isOpen || pathname.includes('notifications')} data-sentry-element="LinkText" data-sentry-source-file="notificationMenu.tsx">{i18n.t('Alerts')}</LinkText>
      </DropdownButton>
      <SitebarDropdown dataTestId="sitebar-notifications-menu" isOpen={isOpen} data-sentry-element="SitebarDropdown" data-sentry-source-file="notificationMenu.tsx">
        <Divider data-sentry-element="Divider" data-sentry-source-file="notificationMenu.tsx">{i18n.t('Notifications')}</Divider>
        <NotificationList limit={NOTIFICATION_MENU_LIMIT} onClick={() => setOpenNotifications(false)} data-sentry-element="NotificationList" data-sentry-source-file="notificationMenu.tsx" />
        {items?.length > NOTIFICATION_MENU_LIMIT && <Link legacyBehavior passHref href="/notifications">
            <SitebarDropdownLink as="a">
              {`+ ${i18n.t('view all notifications')}`}
            </SitebarDropdownLink>
          </Link>}
        {items?.length === 0 && (isLoading ? <Loader /> : <Empty>{i18n.t(`This is where we'll notify you when others follow you or interact with your posts and replies.`)}</Empty>)}
      </SitebarDropdown>
    </Container>;
}
const Container = styled.div`
  position: relative;
  ${Dropdown} {
    right: 0;
  }
  ul li a {
    white-space: normal;
  }
`;
const Empty = styled.p`
  ${text}
  margin: 10px 15px;
`;