// @ts-strict-ignore
import { captureException } from '@sentry/nextjs';
import { getCommunityBannerUrl, getCommunityLogoUrl } from 'src/utils/linkUtils';
import { FormValues } from 'src/modules/community/writePoll/writePollForm';
import { del, post, put } from 'src/utils/api';

export const saveAndCropLogoImage = async (slug: string, formData: FormData) => {
  const { data: { imageHash } } = await post('private/images/group-logos', formData);

  // updates community logo with new image
  const response = await put(`private/communities/${slug}/logo`, {
    logoImageHash: imageHash,
  });

  const imageUrl = getCommunityLogoUrl(imageHash);
  return { response, imageUrl };
};

export const saveAndCropBannerImage = async (slug: string, formData: FormData) => {
  const { data: { imageHash } } = await post('private/images/group-banners', formData);

  // updates community banner with new image
  const response = await put(`private/communities/${slug}/banner`, {
    bannerImageHash: imageHash,
  });

  const imageUrl = getCommunityBannerUrl(imageHash);
  return { response, imageUrl };
};

export const removeMemberFromCommunity = async (slug: string, userId: number) => {
  try {
    const response = await del(`private/communities/${slug}/member/${userId}`);
    return response;
  } catch (ex) {
    captureException(ex);
    return ex.response; // BE returns several failure messages to display on FE
  }
};

export const createPoll = async (slug: string, formData: FormValues) => {
  try {
    const response = await post(`private/polls/${slug}`, formData);
    return response;
  } catch (ex) {
    captureException(ex);
    return null;
  }
};

export const editPoll = async (slug: string, pollId: number, formData: FormValues) => {
  try {
    const response = await put(`private/polls/${slug}/${pollId}`, formData);
    return response;
  } catch (ex) {
    captureException(ex);
    return null;
  }
};
