import styled from 'styled-components';
import i18n from 'src/utils/translate';
import { useDispatch, useSelector } from 'src/store/store';
import { unsetAlert } from 'src/components/alert/actions';
import { useAutoDismiss } from 'src/components/alert/hooks';
import { AlertType } from 'src/components/alert/types';
import Button from 'src/components/buttons/button';
export default function Alert() {
  const dispatch = useDispatch();
  const alert = useSelector(state => state.alert.alert);
  const ref = useAutoDismiss<HTMLDivElement>({
    alert,
    unsetAlert: () => dispatch(unsetAlert())
  });
  if (!alert) return null;
  return <SiteAlert data-testid="site-alert" ref={ref} type={alert.type} data-sentry-element="SiteAlert" data-sentry-component="Alert" data-sentry-source-file="alert.tsx">
      <AlertContainer data-sentry-element="AlertContainer" data-sentry-source-file="alert.tsx">
        {alert.message || i18n.t('There was a problem. Please refresh the page and try again.')}
        <AlertCloseButton dataTestId="alert-close" icon="cross" type="link" onClick={() => dispatch(unsetAlert())} data-sentry-element="AlertCloseButton" data-sentry-source-file="alert.tsx" />
      </AlertContainer>
    </SiteAlert>;
}
const SiteAlert = styled.div<{
  type: AlertType;
}>`
  position: fixed;
  right: 0;
  left: 0;
  top: ${({
  theme
}) => theme.navHeightTop};
  z-index: ${props => props.theme.zindexLiftTop * 2};
  margin: 0;
  border-width: 0 0 1px 0;
  padding: 18px;
  text-align: center;

  background: ${props => props.type === 'success' ? props.theme.colorSuccessBackground : props.theme.colorErrorBackground};
  color: ${props => props.type === 'success' ? props.theme.colorSuccess : props.theme.colorError};

  a {
    color: ${props => props.type === 'success' ? props.theme.colorSuccess : props.theme.colorError};
    text-decoration: underline;
  }
`;
const AlertContainer = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  @media (${({
  theme
}) => theme.overScreenLarge}) {
    margin: 0 auto;
    max-width: ${({
  theme
}) => theme.siteWidth};
    padding: 0 15px;
  }
`;
const AlertCloseButton = styled(Button)`
  margin: 0;
  font-size: ${props => props.theme.fontSizeM};
  color: inherit;
  &:hover, &:focus, &:active {
    color: inherit;
  }
`;