// @ts-strict-ignore
import { RefObject, useEffect, useRef, useState } from 'react';
import { RenderInputComponentProps } from 'react-autosuggest';
import classNames from 'classnames';
import i18n from 'src/utils/translate';
import { SearchBarIconButton } from 'styles-js/buttons';
import Icon from 'src/components/icon';
type Props = {
  renderInputComponentProps: RenderInputComponentProps & {
    key?: string;
  };
  isFocus: boolean;
  query: string;
  inputRef: RefObject<HTMLInputElement>;
  dataTestId?: string;
};
export default function SearchBarInput({
  renderInputComponentProps,
  isFocus,
  query,
  inputRef,
  dataTestId
}: Props) {
  delete renderInputComponentProps.key;
  return <label className={classNames({
    'search-bar__container': true,
    'search-bar__container--focused': isFocus
  })} data-sentry-component="SearchBarInput" data-sentry-source-file="searchBarInput.tsx">
      <input aria-label={i18n.t('Start a search')} ref={inputRef} {...renderInputComponentProps} // eslint-disable-line react/jsx-props-no-spreading
    data-testid={dataTestId} />
      <SearchBarIconButton disabled={!query} type="submit" data-sentry-element="SearchBarIconButton" data-sentry-source-file="searchBarInput.tsx">
        <Icon icon="search" data-sentry-element="Icon" data-sentry-source-file="searchBarInput.tsx" />
        <span className="search-bar__submit-text">{i18n.t('Search')}</span>
      </SearchBarIconButton>
    </label>;
}
export function useFocus({
  isAutoFocus
}: {
  isAutoFocus?: boolean;
} = {}) {
  const inputRef = useRef(null);
  const [isFocus, setIsFocus] = useState(isAutoFocus);
  useEffect(() => {
    if (isFocus) {
      inputRef.current?.focus();
    } else {
      inputRef.current?.blur();
      (document.activeElement as HTMLInputElement)?.blur();
    }
  }, [isFocus]);
  return {
    isFocus,
    setIsFocus,
    inputRef
  };
}