import { ReactNode } from 'react';
import styled from 'styled-components';
import { textSmall } from 'styles-js/mixins/typography';
type Props = {
  children: ReactNode;
  className?: string;
  dataTestId?: string;
  isNew?: boolean;
};
export default function Badge({
  children,
  className = '',
  dataTestId,
  isNew
}: Props): JSX.Element {
  return <Wrapper $isNew={isNew} className={className} data-testid={dataTestId} data-sentry-element="Wrapper" data-sentry-component="Badge" data-sentry-source-file="badge.tsx">
      {children}
    </Wrapper>;
}
const Wrapper = styled.span<{
  $isNew?: boolean;
}>`
  ${textSmall}
  padding: 2px 7px;
  border-radius: 15px;
  font-size: 14px;
  background-color: ${({
  $isNew,
  theme
}) => $isNew ? theme.colorYellow : theme.colorBlueBackground};

  @media (${({
  theme
}) => theme.underScreenSmall}) {
    font-size: 12px;
  }
`;